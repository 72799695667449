<template>
	<div v-if="loggedIn">
        <!-- <nav class="navbar has-background-white-ter is-spaced has-shadow" role="navigation" aria-label="main navigation"> -->
		<nav class="navbar is-info" role="navigation" aria-label="main navigation">
			<div class="navbar-brand">
                    <a class="is-size-4 navbar-item">TT</a>
                    <!-- <img src="taratech.webp"> -->
				<a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" v-on:click="showNav = !showNav" v-bind:class="{ 'is-active' : showNav }">
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
			</div>

			<div id="navbarBasicExample" class="navbar-menu"  v-bind:class="{ 'is-active' : showNav }">
				<div class="navbar-start">
					<router-link :to="{ name : 'AppDashboard' }" class="navbar-item">Přehled</router-link>
					<router-link :to="{ name : 'AppReports' }" class="navbar-item">Reporty</router-link>
                    <!--
					<router-link :to="{ name : 'AppInfo' }" class="navbar-item">Basic info</router-link>
					<router-link :to="{ name : 'AppSettings' }" class="navbar-item">Settings</router-link>
                    -->
					<router-link :to="{ name : 'AppAbout' }" class="navbar-item">O aplikaci</router-link>

				</div>

				<div class="navbar-end">
					<div class="navbar-item">
                        <a class="button is-light" @click="LogoutClick()"><strong>Odhlášení</strong></a>
					</div>
				</div>
			</div>
		</nav>

        <div v-if="error_app !== ''">
            <br/>
			<div class="box">
				<div class="notification is-danger is-light">
					<strong>Pozor, nastala chyba.</strong>
					<p>{{ error_app }} </p>
					<p>Zkuste stránku obnovit a znovu se přihlásit.</p>
				</div>
			</div>

            <br/>

        </div>
		<router-view/>
	</div>

	<div v-if="!loggedIn">
		<UserLogin/>
	</div>

</template>

<script>

import { store } from '../store.js'
import { libapi } from '../libapi.js'
import UserLogin from './UserLogin.vue'

export default {
	name: 'MainPage',
	components: {
		UserLogin,
	},
	data : function() {
		return {
			showNav: false
		}
	},
	computed : {
        error_app : function() {
			return store.error_app
		},
        loggedIn : function() {
			return store.login.logged_in
		},
	},
	methods: {
		LogoutClick: function() {
			libapi.Logout()
		},
	},
    mounted: function() {
        //libapi.Login( "infiny", "dukom" )
    }
}
</script>

