<template>
    <div class="box">
        <div class="title is-3"></div>
        <span @click="Dbg('uiswap')">Verze</span> :  {{ version }} ( {{ app_build_time }} )
        <br/>
        API verze : {{ info.version }}
        <br/>
        <div class="title is-6"></div>
    </div>

</template>

<script>
import { store } from '../store.js'
import { version } from '../version_gen.js'

export default {
    components: {
    },
    computed : {
        info : function() {
            return store.info
        },
        version : function() {
            return version.version
        },
        app_build_time : function() {
            return version.build_time
        },
    },

    data: function() {
        return {
        }
    },
    methods: {
        Dbg(arg) {
            store.SetDbg(arg)
        }, 
    },
    mounted: function() {
    }

}
</script>

<style scoped>
</style>

