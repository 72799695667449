
import {reactive} from 'vue';

export const store = reactive({ 
    login : {
        logged_in : false,
        username : '???',
    },
    info : {},

    error_app : '',

    sess_x89 : '',

    // zda nastavujeme nejake debug flagy
    debug : {},

    IsUiModeExt1 : function() {
        const uim = store.info?.ui_mode
        return uim == 1
    },
    ErrorAppClear : function() {
        store.error_app = ''
    },
    SetDbg : function(arg) {
        if ( arg == "uiswap" ) {
            store.info.ui_mode = store.info.ui_mode == 0 ? 1 : 0;
        }
    },
})

