
export const tmblk = {

    def : {
        HO : {
            minusStep : function(tm) {
                tm.setHours(tm.getHours()-1)
                return tm
            },
        },
        DA : {
            minusStep : function(tm) {
                tm.setHours(tm.getHours()-24)
                return tm
            },
        },
        MO : {
            minusStep : function(tm) {
                const currentMonth = tm.getMonth();
                if (currentMonth === 0) {
                    tm.setMonth(11)
                    tm.setFullYear(tm.getFullYear() - 1)
                } else {
                    tm.setMonth(currentMonth - 1)
                }
                return tm;
            },
        },
        YE : {
            minusStep : function(tm) {
                tm.setFullYear(tm.getFullYear() - 1)
                return tm;
            },
        },
    },

    AvgConvert( tb, timeType  ) {

        let dd = tmblk.def[timeType]
        if ( dd === null ) {
            console.log("Invalid timeType: ", timeType)
            return false
        }
        if ( tb.dtype !== 'AVG' ) {
            console.log("tmblk is not AVG: ", tb.dtype)
            return false
        }

        const tm0 = new Date(tb.tm0)
        let tm = new Date(tm0)


        let retData = []
        for ( let i = 0; i < tb.blk.length; i++ ) {
            let v = 0
            let c = tb.blk[i].c
            let it = { x : tm.getTime(), y : new Number(0).toFixed(2) }
            if ( c > 0 ) {
                v = tb.blk[i].v / c
                it = { x : tm.getTime(), y : v.toFixed(2) }
            }
            retData.push(it)
            tm = dd.minusStep(tm)
        }
        return { data : retData }
    },

    IncrConvert( tb, timeType, minusAllowed ) {

        let dd = tmblk.def[timeType]
        if ( dd === null ) {
            console.log("Invalid timeType: ", timeType)
            return false
        }
        if ( tb.dtype !== 'INCR' ) {
            console.log("tmblk is not INCR: ", tb.dtype)
            return false
        }

        const tm0 = new Date(tb.tm0)
        let tm = new Date(tm0)

        let retData = []
        let vMax = 0
        for ( let i = 0; i < tb.blk.length; i++ ) {
            let v = new Number(tb.blk[i].V - tb.blk[i].v)
            // kdyz nechceme aby jsme sli do minus
            // nekdy tu vyjde minus kuli dopocitavani desetinnych mist u TOT_KWH
            if ( !minusAllowed && v < 0 ) { 
                v = new Number(0.0)
            }
            if ( v > vMax ) vMax = v
            let it = { x : tm.getTime(), y : v.toFixed(2) }
            retData.push(it)
            tm = dd.minusStep(tm)
        }

        return { data : retData, max : vMax }
    },


}

