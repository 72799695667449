<template>

    <div class="box">
		<div class="is-size-3">Reporty</div>

        <div v-if="dataError !== ''">
            <br/>
			<div class="p-10">
				<div class="notification is-danger is-light">
					<strong>Pozor chyba.</strong>
					<p>{{ dataError }} </p>
					<p>...</p>
				</div>
			</div>
            <br/>
        </div>


        <div class="field has-addons">
            <p class="control">
                <button class="button" @click="TmIntClick('hours')" :class="[ tmInterval=='hours' ? 'is-info' : '' ]">Hodiny</button>
            </p>
            <p class="control">
                <button class="button" @click="TmIntClick('days')" :class="[ tmInterval=='days' ? 'is-info' : '' ]">Dny</button>
            </p>
            <p class="control">
                <button class="button" @click="TmIntClick('months')" :class="[ tmInterval=='months' ? 'is-info' : '' ]">Měsíce</button>
            </p>
            <p class="control">
                <button class="button" @click="TmIntClick('years')" :class="[ tmInterval=='years' ? 'is-info' : '' ]">Roky</button>
            </p>
        </div>


        <table class="table is-striped" width="100%">
            <thead>
                <tr>
                    <th class="has-text-left">Datum</th>
                    <th class="has-text-right">Vyrobeno kWH</th>
                    <th class="has-text-right">Import kWH</th>
                    <th class="has-text-right">Export kWH</th>
                    <th class="has-text-right">Bat %</th>
                </tr>
            </thead>
            <tbody>
                    <template v-for="(v,i) in tableData" :key="i">
                    <tr>
                        <td class="has-text-left">{{ FormatDt(v.ts, tmInterval ) }} </td>
                        <td class="has-text-right">{{ v.tot }} </td>
                        <td class="has-text-right">{{ v.imp }} </td>
                        <td class="has-text-right">{{ v.exp }} </td>
                        <td class="has-text-right">{{ v.bat }} </td>
                    </tr>
                    </template>
            </tbody>
        </table>

    </div>

</template>

<script>

import { libapi } from '../libapi.js'
import { tmblk } from '../tmblk.js'

export default {
    name: 'AppReports',

    data : function() {
        return {
            tmInterval : 'hours',
            dataSe : {},
            tableData : [],
            dataError : "",
        }
    },
    computed : {
    },
    methods: {
        FormatDt : function( ts, tmInterval ) {
            let date = new Date(ts)
            const hours = String(date.getHours()).padStart(2, '0') // HH
            //const minutes = String(date.getMinutes()).padStart(2, '0') // mm
            //const seconds = String(date.getSeconds()).padStart(2, '0') // ss
            const day = String(date.getDate()).padStart(2, '0') // DD
            const month = String(date.getMonth() + 1).padStart(2, '0') // MM (měsíc je 0-indexovaný)
            const year = date.getFullYear() // YYYY


            //return [`${day}.${month}.${year} ${hours}:00:00 `,`${offset} (${timeZone})`]
            if ( tmInterval == "hours" ) {
                return `${day}.${month}.${year} ${hours}:00:00 `
            }
            return `${day}.${month}.${year}`
        },

        // vraci jen vypocitane casove bloky pro dany typ 
        // je to potreba pro inicializaci v reportech
        // protoze pokud nejaky dev nefunguje v grafech nebude aktualni datum a cas
        // ale bude nekde v minulosti, takze data k sobe musime davat podle TS
        CreateTimeTable : function( sz, timeType ) {
            let dd = tmblk.def[timeType]
            if ( dd === null ) {
                console.log("Invalid timeType: ", timeType)
                return false
            }
            let tm = new Date()
            if ( timeType == "HO" ) {
                tm.setMinutes(0, 0, 0)
            } else if ( timeType == "DA" ) {
                tm.setHours(0, 0, 0, 0)
            } else if ( timeType == "MO" ) {
                tm.setDate(1)
                tm.setHours(0, 0, 0, 0)
            } else if ( timeType == "YE" ) {
                tm.setMonth(0, 1)
                tm.setHours(0, 0, 0, 0)
            }
            let retData = []
            for ( let i = 0; i < sz; i++ ) {
                let it = { 'ts' : tm.getTime() }
                retData.push(it)
                tm = dd.minusStep(tm)
            }
            return retData 

        },

        TableDataFill: function() {
            this.dataError = ""
            let td = null
            const dataKeys = [
                {kName : 'g_in_tot_kwh', itmName : 'tot',}, 
                {kName : 'g_bat_proc', itmName : 'bat',}, 
                {kName : 'g_em_tot_kwh', itmName : 'imp',}, 
                {kName : 'g_em_tot_exp_kwh', itmName : 'exp',} ]
            for (const ki in dataKeys) {
                const kName = dataKeys[ki].kName
                const itmName = dataKeys[ki].itmName

                let g1 = this.dataSe?.[kName]?.[this.tmInterval] || "?"
                if ( g1 == "?" ) {
                    console.log(`Not found in data (kName: ${kName} tmInterval: ${this.tmInterval}`)
					continue
                }

                let convData = false
                if ( g1.dtype == "AVG" ) {
                    convData = tmblk.AvgConvert( g1, g1.ttype )
                } else if ( g1.dtype == "INCR" ) {
                    convData = tmblk.IncrConvert( g1, g1.ttype, false )
                }
                if ( convData !== false ) {
                    if ( td === null ) {
                        td = this.CreateTimeTable(convData.data.length,g1.ttype) 
                    }
                    // pro jistotu zkontrolujeme zda delky poli jsou stejna
                    if ( td.length != convData.data.length ) {
                        this.dataError = "Chyba v datech 1"
                    }
                    // pri chybe muze dojit k tomu, ze data nemusi zacinat od stejneho casu,
                    // takze musime hodnoty umistovat podle timestamp
                    for( let i2 = 0; i2 < convData.data.length; i2++ ) {
                        const ts = convData.data[i2].x
                        const tsData = convData.data[i2].y
                        for( let i = 0; i < td.length; i++ ) {
                            if ( ts == td[i]['ts'] ) {
                                td[i][itmName] = tsData
                            }
                        }
                    }
                }
            }
            this.tableData = td
        },

        TmIntClick: function( t ) {
            this.tmInterval = t
            this.TableDataFill()
        },
        ApiData: function() {
            libapi.Data().then(
                (resp) => {
                    this.dataSe = resp.data
                    this.TableDataFill()
                })
        },
    },

    mounted: function() {
        this.ApiData()
    },
	beforeUnmount: function() {
	},
}
</script>

<style>
</style>
